 <template>
	<el-dialog title="订单详情/修改" top="1vh" width="1080px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="80px">
			<div class="big_tit" style="margin:0">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="订单批次" style="width:25%">
					<el-input v-model="data_in_page.tord_batch_num" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单编号" style="width:25%">
					<el-input v-model="data_in_page.tord_num" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="状态" style="width:25%">
					<el-input v-model="data_in_page.status_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">时效/状态</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="创建时间" style="width:25%">
					<el-input v-model="data_in_page.create_time_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="上次修改" style="width:25%">
					<el-input v-model="data_in_page.update_time_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="匹配完成" style="width:25%">
					<el-input v-model="data_in_page.matched_time_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">客户和代理/物流公司</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="客户信息" style="width:50%">
					<el-input v-model="data_in_page.customer_user_info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="代理信息" style="width:50%">
					<el-input v-model="data_in_page.customer_agent_info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="物流公司" style="width:50%">
					<el-input v-model="data_in_page.logistics_company_info_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">发车网点/到货网点</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车网点" style="width:50%">
					<el-input v-model="data_in_page.tord_case_node_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货网点" style="width:50%">
					<el-input v-model="data_in_page.tord_aim_node_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">中转网点</div>
			<div class="tab1_inner">
				<el-form-item 
					v-for="(item,index) in data_in_page.tord_transit_node_text_list" 
					:key="index" class="el_form_item" style="width:33%" label-width="10px" 
				>
					<el-input :value="item" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">运单原始信息</div>
			<div class="tab1_inner">
				<el-form-item 
					v-for="(val,key) in data_in_page.tord_origin_info" 
					:key="key" :label="key" label-width="110px"
					class="el_form_item" style="width:25%"
				>
					<el-input :value="val" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">转换前货物汇总</div>
			<div class="tab1_inner">
				<el-form-item 
					v-for="(val,key) in data_in_page.cargo_origin_contents" 
					:key="key" :label="key" label-width="60px"
					class="el_form_item" style="width:20%"
				>
					<el-input :value="val" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">转换后货物汇总</div>
			<div class="tab1_inner">
				<el-form-item 
					v-for="(val,key) in data_in_page.cargo_changed_contents" 
					:key="key" :label="key" label-width="60px"
					class="el_form_item" style="width:20%"
				>
					<el-input :value="val" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">物流公司对我的报价</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="重量单价" style="width:30%">
					<el-input v-model="data_in_page.weight_price_for_me" disabled>
						<template slot="append">元/吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积单价" style="width:30%">
					<el-input v-model="data_in_page.volume_price_for_me" disabled>
						<template slot="append">元/方</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="件数单价" style="width:40%">
					<el-input v-model="data_in_page.number_price_for_me" disabled>
						<template slot="append">元/件</template>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">我对客户的报价</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="重量单价" style="width:30%">
					<el-input v-model="data_in_page.weight_price_for_cus" disabled>
						<template slot="append">元/吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积单价" style="width:30%">
					<el-input v-model="data_in_page.volume_price_for_cus" disabled>
						<template slot="append">元/方</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="件数单价" style="width:40%">
					<el-input v-model="data_in_page.number_price_for_cus" disabled>
						<template slot="append">元/件</template>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">货物数量与运费</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物重量" style="width:30%">
					<el-input v-model="data_in_page.cargo_weight" disabled>
						<template slot="append">吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="重量运费" style="width:30%">
					<el-input v-model="data_in_page.weight_freight" disabled>
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物体积" style="width:30%">
					<el-input v-model="data_in_page.cargo_volume" disabled>
						<template slot="append">吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积运费" style="width:30%">
					<el-input v-model="data_in_page.volume_freight" disabled>
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物件数" style="width:30%">
					<el-input v-model="data_in_page.cargo_number" disabled>
						<template slot="append">吨</template>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="件数运费" style="width:30%">
					<el-input v-model="data_in_page.number_freight" disabled>
						<template slot="append">元</template>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装货地</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="省" label-width="35px" style="width:13%">
					<el-input v-model="data_in_page.case_prov" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="市" label-width="35px" style="width:13%">
					<el-input v-model="data_in_page.case_city" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="区" label-width="35px" style="width:13%">
					<el-input v-model="data_in_page.case_county" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="地址" label-width="45px" style="width:26%">
					<el-input v-model="data_in_page.case_addr" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系人" label-width="60px" style="width:15%">
					<el-input v-model="data_in_page.case_link_man" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" label-width="80px" style="width:20%">
					<el-input v-model="data_in_page.case_link_tel" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">卸货地</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="省" label-width="35px" style="width:13%">
					<el-input v-model="data_in_page.aim_prov" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="市" label-width="35px" style="width:13%">
					<el-input v-model="data_in_page.aim_city" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="区" label-width="35px" style="width:13%">
					<el-input v-model="data_in_page.aim_county" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="地址" label-width="45px" style="width:26%">
					<el-input v-model="data_in_page.aim_addr" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系人" label-width="60px" style="width:15%">
					<el-input v-model="data_in_page.aim_link_man" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" label-width="80px" style="width:20%">
					<el-input v-model="data_in_page.aim_link_tel" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">其他数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="匹配失败原因" label-width="110px" style="width:100%">
					<el-input v-model="data_in_page.match_faild_reason" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单备注" label-width="110px" style="width:100%">
					<el-input v-model="data_in_page.mark" disabled></el-input>
				</el-form-item>
			</div>

		</el-form>

		<!-- <div class="bottom_btns">
			<el-button type="primary" @click="sub">提交</el-button>
			<el-button type="danger" @click="clear">还原</el-button>
		</div> -->

	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			data:Object,
			customer_user_list:Array,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//页面内不可编辑数据
				data_in_page:{
					tord_batch_num:'',
					tord_num:'',
					status_text:'',

					create_time_text:'',
					update_time_text:'',
					matched_time_text:'',

					customer_user_info_text:'',
					customer_agent_info_text:'',
					logistics_company_info_text:'',

					tord_case_node_text:'',
					tord_transit_node_text_list:[],
					tord_aim_node_text:'',

					tord_origin_info:{},
					cargo_origin_contents:{},
					cargo_changed_contents:{},

					weight_price_for_me:'',
					volume_price_for_me:'',
					number_price_for_me:'',

					weight_price_for_cus:'',
					volume_price_for_cus:'',
					number_price_for_cus:'',

					cargo_weight:'',
					cargo_volume:'',
					cargo_number:'',

					weight_freight:'',
					volume_freight:'',
					number_freight:'',

					case_prov:'',
					case_city:'',
					case_county:'',
					case_link_man:'',
					case_link_tel:'',
					case_addr:'',

					aim_prov:'',
					aim_city:'',
					aim_county:'',
					aim_link_man:'',
					aim_link_tel:'',
					aim_addr:'',

					match_faild_reason:'',
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

					//获取运输网点
					this.get_tord_node()

				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//提交
			sub(){

				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少线路名称'
					});
					return;
				}

				if(!this.form.logistics_company_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少物流公司'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.weight_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少重量单价'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.volume_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少体积单价'
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.number_price)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少件数单价'
					});
					return;
				}

				if(!this.node_choose.case_node_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少装货网点'
					});
					return;
				}

				if(!this.node_choose.aim_node_num.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少卸货网点'
					});
					return;
				}

				//中转网点
				let transit_node_nums=[];
				for(var transit_node_item of this.node_choose.transit_nodes){
					transit_node_nums.push(transit_node_item.logistics_node_num);
				}

				//提交数据
				this.$my.other.confirm({
					content:"点击确定修改路线",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'logistics',
								ctr:'route_edit_by_admin',
								logistics_route_num:this.data.logistics_route_num,
								case_node_num:this.node_choose.case_node_num,
								aim_node_num:this.node_choose.aim_node_num,
								transit_node_nums:transit_node_nums,
								...this.form,
							},
							callback:(data)=>{

								//通知
								this.$emit("edited");
							}
						});
					}
				});
			},

			//打开网点选择界面
			node_choose_open(type){
				if(!this.form.logistics_company_num){
					this.$my.other.msg({
						type:"warning",
						str:'请先确定公司'
					});
					return;
				}
				this.node_choose.is_show++
				this.node_choose.type=type
			},
			node_clear(){
				this.node_choose.case_node_info_text=''
				this.node_choose.case_node_num=''
				this.node_choose.transit_nodes=[]
				this.node_choose.aim_node_info_text=''
				this.node_choose.aim_node_num=''
			},
			transit_node_del(index){
				this.node_choose.transit_nodes.splice(index,1);
			},
			node_choosed(obj){

				switch(this.node_choose.type){

					case 'case'://装货网点
						for(var transit_node_item of this.node_choose.transit_nodes){
							if(obj.logistics_node_num==transit_node_item.logistics_node_num){
								this.$my.other.msg({
									type:"warning",
									str:'装货网点不能与中转网点相同'
								});
								return;
							}
						}
						if(obj.logistics_node_num==this.node_choose.aim_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'装货网点不能与到货网点相同'
							});
							return;
						}
						this.node_choose.case_node_num=obj.logistics_node_num
						this.node_choose.case_node_info_text=`${obj.logistics_park_info.city}${obj.logistics_park_info.county} / ${obj.logistics_park_info.name} / ${obj.name}`;
						break;

					case 'transit'://中转网点
						if(obj.logistics_node_num==this.node_choose.case_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'中转网点不能与装货网点相同'
							});
							return;
						}
						for(var transit_node_item of this.node_choose.transit_nodes){
							if(obj.logistics_node_num==transit_node_item.logistics_node_num){
								this.$my.other.msg({
									type:"warning",
									str:'中转网点重复'
								});
								return;
							}
						}
						if(obj.logistics_node_num==this.node_choose.aim_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'中转网点不能与到货网点相同'
							});
							return;
						}

						//置入
						this.node_choose.transit_nodes.push(obj)
						break;

					case 'aim'://卸货网点
						for(var transit_node_item of this.node_choose.transit_nodes){
							if(obj.logistics_node_num==transit_node_item.logistics_node_num){
								this.$my.other.msg({
									type:"warning",
									str:'卸货网点不能与中转网点相同'
								});
								return;
							}
						}
						if(obj.logistics_node_num==this.node_choose.case_node_num){
							this.$my.other.msg({
								type:"warning",
								str:'卸货网点不能与装货网点相同'
							});
							return;
						}
						this.node_choose.aim_node_num=obj.logistics_node_num
						this.node_choose.aim_node_info_text=`${obj.logistics_park_info.city}${obj.logistics_park_info.county} / ${obj.logistics_park_info.name} / ${obj.name}`;
						break;
				}

				//关闭弹出层
				this.node_choose.is_show=0
			},

			//初始化
			init(){

				//不可修改数据
				this.data_in_page.tord_batch_num=this.data.tord_batch_num
				this.data_in_page.tord_num=this.data.tord_num
				this.data_in_page.status_text=this.data.status_text

				this.data_in_page.create_time_text=this.data.create_time_text
				this.data_in_page.update_time_text=this.data.update_time_text
				if(this.data.status!=1){
					this.data_in_page.matched_time_text=this.data.matched_time_text
				}
				
				
				this.data_in_page.customer_user_info_text=
					this.data.customer_user_info.tel+'/'+
					this.data.customer_user_info.user_name+'/'+
					this.data.customer_user_info.company_name

				if(this.data.status==2){
					this.data_in_page.customer_agent_info_text=
						this.data.customer_agent_info.name+'/'+
						this.data.customer_agent_info.city+
						this.data.customer_agent_info.county

					this.data_in_page.logistics_company_info_text=
						this.data.logistics_company_info.name+'/'+
						this.data.logistics_company_info.link_man+'/'+
						this.data.logistics_company_info.link_tel+'/'+
						'线路:'+this.data.logistics_route_info.name
				}
				
				
				this.data_in_page.tord_origin_info=JSON.parse(this.data.tord_origin_info)
				this.data_in_page.cargo_origin_contents=JSON.parse(this.data.cargo_origin_contents)
				this.data_in_page.cargo_changed_contents=JSON.parse(this.data.cargo_changed_contents)

				this.data_in_page.weight_price_for_me=this.data.weight_price_for_me
				this.data_in_page.volume_price_for_me=this.data.volume_price_for_me
				this.data_in_page.number_price_for_me=this.data.number_price_for_me

				this.data_in_page.weight_price_for_cus=this.data.weight_price_for_cus
				this.data_in_page.volume_price_for_cus=this.data.volume_price_for_cus
				this.data_in_page.number_price_for_cus=this.data.number_price_for_cus

				this.data_in_page.cargo_weight=this.data.cargo_weight
				this.data_in_page.cargo_volume=this.data.cargo_volume
				this.data_in_page.cargo_number=this.data.cargo_number

				this.data_in_page.weight_freight=this.data.weight_freight
				this.data_in_page.volume_freight=this.data.volume_freight
				this.data_in_page.number_freight=this.data.number_freight
				
				this.data_in_page.case_prov=this.data.case_prov
				this.data_in_page.case_city=this.data.case_city
				this.data_in_page.case_county=this.data.case_county
				this.data_in_page.case_link_man=this.data.case_link_man
				this.data_in_page.case_link_tel=this.data.case_link_tel
				this.data_in_page.case_addr=this.data.case_addr

				this.data_in_page.aim_prov=this.data.aim_prov
				this.data_in_page.aim_city=this.data.aim_city
				this.data_in_page.aim_county=this.data.aim_county
				this.data_in_page.aim_link_man=this.data.aim_link_man
				this.data_in_page.aim_link_tel=this.data.aim_link_tel
				this.data_in_page.aim_addr=this.data.aim_addr

				this.data_in_page.match_faild_reason=this.data.match_faild_reason
				this.data_in_page.mark=this.data.mark
			},

			//获取运输网点
			get_tord_node(){

				if(this.data.status!=2)return

				//读取数据
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_node_list_by_admin',
						tord_num:this.data.tord_num
					},
					callback:(data)=>{

						for(var node of data.list){

							if(node.type==1){//发车网点
								this.data_in_page.tord_case_node_text=
									node.city+node.county+node.addr+'/'+
									node.link_man+'/'+node.link_tel
							}

							if(node.type==2){//中转网点
								this.data_in_page.tord_transit_node_text_list.push(
									node.city+node.county+node.addr+'/'+
									node.link_man+'/'+node.link_tel
								)	
							}

							if(node.type==3){//到货网点
								this.data_in_page.tord_aim_node_text=
									node.city+node.county+node.addr+'/'+
									node.link_man+'/'+node.link_tel
							}
						}
					}
				});
			},
			
			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定还原本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	.el_form_item{
		margin: 0;
		padding-top: 10px;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.inner_div{
		width:85.977%;
		height: 78px;
		line-height:39px;
		border: 1px solid #E4E7ED;
		background-color: #F5F7FA;
		border-radius: 4px;
		display:flex;
		flex-wrap:wrap;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>