 <template>
	<el-dialog title="创建订单搜全" top="1vh" width="60%" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="80px">
			<div class="big_tit" style="margin:0">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="后台用户" style="width:60%">
					<el-select class="el_inner_width" v-model="form.admin_user_num" clearable>
						<el-option 
							v-for="(item,index) of admin_user_list" 
							:key="index" :value="item.admin_user_num" 
							:label="`${item.tel}/${item.name}`" 
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="备注" style="width:40%">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>

			<div class="big_tit">
				<div>选择</div>
				<el-button @click="customer_choose_open" type="primary" size="mini" style="position:absolute;left:110px;top:8px">选择</el-button>
			</div>
			<div class="tab_height">
				<el-table :data="excel_list" :border="true" :stripe="true" size="small" height="100%">
					<el-table-column label="文件名称" prop="file_name"></el-table-column>
					<el-table-column label="订单条数" width="110">
						<template slot-scope="scope">
							{{scope.row.file_data.length-scope.row.data_start_column}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="110">
						<template slot-scope="scope">
							<el-button @click="excel_del(scope.$index)" size="mini" type="text">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

		<!-- 读取excel弹出层 -->
		<el-dialog 
			title="上传进度" 
			width="600px" 
			:close-on-click-modal="false" 
			:close-on-press-escape="false" 
			:show-close="false" 
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="sub_progress.is_show" 
		>
			<div style="margin-top:-20px">
				<div v-if="sub_progress.step==1" style="text-align: center;height: 80px;line-height: 80px;">批次创建中</div>
				<div v-if="sub_progress.step==2" style="text-align: center;height: 80px;line-height: 80px;">
					文件总数:{{sub_progress.file_total}} / 已上传:{{sub_progress.file_uploaded_total}} / 第{{sub_progress.file_uploading+1}}个文件上传中...
				</div>
			</div>
		</el-dialog>

	</el-dialog>
</template>

<script>
	import xlsx from 'xlsx'
	export default {
		props:{
			is_show:Number,
			admin_user_list:Array,
			customer_user_list:Array,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,
				sub_swh:false,

				//表单
				form:{
					admin_user_num:'',
					customer_user_nums:[],
					mark:'',
				},

				//已被选定的客户
				customer_user_choosed:[],
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//提交
			sub(){

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:"提交中,请稍后..."
					});
					return;
				}

				if(!this.customer_user_choosed.customer_user_num){
					this.$my.other.msg({
						type:'warning',
						str:"请先选择客户"
					});
					return;
				}

				if(this.excel_list.length==0){
					this.$my.other.msg({
						type:'warning',
						str:"请上传至少一个excel"
					});
					return;
				}

				//车牌号
				let truck_plate_nums=this.form.truck_plate_nums.split(',')
				let truck_plate_num_list=[];
				for(var item of truck_plate_nums){
					if(!item.trim()){
						continue;
					}
					if(!this.$my.check.is_plate_num(item.trim())){
						this.$my.other.msg({
							type:'warning',
							str:`车牌号(${item})格式有误,请检查车牌号格式,或者检查逗号是否正确`
						});
						return;
					}
					if(truck_plate_num_list.indexOf(item.trim())!=-1){
						this.$my.other.msg({
							type:'warning',
							str:`车牌号(${item})已经存在`
						});
						return;
					}
					truck_plate_num_list.push(item.trim())
				}

				//开始提交
				this.sub_swh=true;
				this.sub_progress.is_show=true;
				this.sub_progress.step=1

				//调用接口
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_add_by_admin',
						truck_plate_num_list:truck_plate_num_list,
						...this.form
					},
					callback:(data)=>{

						//缓存原始订单批次编号
						this.tord_batch_num=data.tord_batch_num

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"批次创建成功"
						});

						//进入第二步
						this.sub_progress.step=2
						this.sub_progress.file_total=this.excel_list.length;//文件总数
						this.sub_progress.file_uploaded_total=0;//已上传文件数量

						//开始上传运单数据
						this.file_upload(0);
					}
				});
			},

			//初始化
			init(){

				//解除提交锁
				this.sub_swh=false
				
				//清空表单数据
				this.form.admin_user_num="";
				this.form.customer_user_nums=[];
				this.form.mark="";

				//清空已选定客户
				this.customer_user_choosed=[]
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position:relative;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	.el_inner_div{
		cursor: pointer;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.tab_height{
		height:300px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

	.el-dialog-s{
		z-index: 15;
	}

</style>